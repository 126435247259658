import './assets/css/App.css';

import {
  Disclaimer,
  MainForm,
  PageTitle,
  ThankYouAlternative,
  ThankYouLenderList,
  TopBarAndFooter,
} from './components';
import {
  EXIT_PAGE_MESSAGE,
  THANK_YOU_ALTERNATIVE_ROUTE,
  THANK_YOU_LENDER_LIST_ROUTE,
} from './lib/constants';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  useAnura,
  useExitConfirmation,
  useExternalScript,
  useJornaya,
  useTrustedForm,
} from './lib/hooks';

import { CustomPixelHelmet } from './components/CustomComponents';

function App() {
  useExitConfirmation(EXIT_PAGE_MESSAGE);
  useJornaya();
  useTrustedForm();
  useAnura();
  useExternalScript(
    'https://rgr-js.s3.us-west-2.amazonaws.com/tracking.js',
    'tracking_env',
    process.env.REACT_APP_ENV
  );

  return (
    <div className="page">
      <PageTitle title={process.env.REACT_APP_TAB_NAME} />
      <CustomPixelHelmet />
      <Router>
        <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <TopBarAndFooter showNumber={true} />
                  <MainForm />
                  <div>
                    <div className="disclaimer">
                      <Disclaimer />
                    </div>
                    <TopBarAndFooter className="top-bar-and-footer__bottom-gray " />
                  </div>
                </>
              }
            />

            <Route
              path={THANK_YOU_LENDER_LIST_ROUTE}
              element={
                <>
                  <TopBarAndFooter className="top-bar-and-footer__bottom-gray " />
                  <ThankYouLenderList />
                </>
              }
            />
            <Route
              path={THANK_YOU_ALTERNATIVE_ROUTE}
              element={
                <>
                  <TopBarAndFooter />
                  <ThankYouAlternative />
                </>
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
