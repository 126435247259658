export const CustomLink = ({ href, children }) => (
  <a
    href={href}
    onClick={(e) => e.preventDefault()}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
