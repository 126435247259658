import './CustomSelect.css';

import { useEffect, useRef, useState } from 'react';

import { useFormContext } from 'react-hook-form';

const CustomSelect = ({
  options,
  name,
  value,
  register,
  onChange,
  placeholder = 'Please Select',
  errorMessage = 'Fill out required field',
  errors = {},
  customRef,
  disableMargin = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || placeholder);
  const dropdownRef = useRef(null);
  const { setValue } = useFormContext();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    onChange({ [name]: option.value });
    setValue(name, option.value);
    setSelectedOption(option.text);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`custom__select-container ${
        disableMargin && 'custom__select-container__no-margin'
      }`}
    >
      <div
        className={`custom__select ${errors[name] && 'custom__select-error'} ${
          disableMargin && 'custom__select__margin-top'
        }`}
        style={{ color: `${!value ? 'var(--medium-gray)' : 'inherit'}` }}
        ref={customRef}
        onClick={toggleDropdown}
      >
        {selectedOption}
        <span className="custom__select-arrow" />
      </div>
      {isOpen && (
        <ul className="custom__select-dropdown" ref={dropdownRef}>
          {options.map((option, index) => (
            <li
              key={`${option.text}-${index}`}
              onClick={() => handleOptionClick(option)}
            >
              {option.text}
            </li>
          ))}
        </ul>
      )}
      <input
        type="hidden"
        value={value}
        {...register(name, { required: errorMessage })}
      />
    </div>
  );
};

export default CustomSelect;
