import {
  AppProvider,
  DisclaimerProvider,
  FormDataProvider,
  GeoLocationProvider,
  LenderProvider,
} from './index';

import { HelmetProvider } from 'react-helmet-async';

const CombinedProviders = ({ children }) => (
  <HelmetProvider>
    <AppProvider>
      <DisclaimerProvider>
        <LenderProvider>
          <GeoLocationProvider>
            <FormDataProvider>{children}</FormDataProvider>
          </GeoLocationProvider>
        </LenderProvider>
      </DisclaimerProvider>
    </AppProvider>
  </HelmetProvider>
);

export default CombinedProviders;
