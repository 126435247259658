import './CustomModalContent.css';

import Modal from '../../Modal/Modal';
import parse from 'html-react-parser';

function CustomModalContent({
  title,
  content = '',
  isOpen,
  onClose,
  isHtml = false,
  clickEvent,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <header className="content-modal__header">
        <h2 className="content-modal__title">{title}</h2>
        <button className="content-modal__close-btn-icon" onClick={onClose}>
          &times;
        </button>
      </header>
      <div
        className="content-modal__body"
        onClick={(event) => clickEvent(event)}
      >
        {isHtml ? parse(content) : content}
      </div>
      <footer className="content-modal__footer">
        <button className="content-modal__close-btn" onClick={onClose}>
          Close
        </button>
      </footer>
    </Modal>
  );
}

export default CustomModalContent;
