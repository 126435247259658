import React, { createContext, useContext, useEffect, useState } from 'react';

const GeoLocationContext = createContext(null);

export function useGeoLocationContext() {
  return useContext(GeoLocationContext);
}

export function GeoLocationProvider(props) {
  const { children } = props;

  const [geoContext, setGeoContext] = useState({});

  useEffect(() => {
    if (typeof window !== 'undefined' && window.geoip2) {
      window.geoip2.city((data) => {
        setGeoContext(data);
      });
    }
  }, []);

  return (
    <GeoLocationContext.Provider value={geoContext}>
      {children}
    </GeoLocationContext.Provider>
  );
}
