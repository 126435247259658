import './MainForm.css';
import './Steps/steps.css';

import {
  DEFAULT_MAIN_SUBTITLE,
  DEFAULT_MAIN_TITLE,
  LOCAL_PHONE_NUMBER,
} from '../../lib/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { Step1, Step2, Step3 } from './Steps';
import { getErrorMessage, showELocalPhone, submitForm } from '../../lib/utils';
import {
  useAppContext,
  useFormDataContext,
  useLendersContext,
} from '../../lib/contexts';

import BBBLogo from '../../assets/img/logo_bbb.png';
import { CustomLoader } from '../CustomComponents';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function MainForm({
  title = DEFAULT_MAIN_TITLE,
  subtitle = DEFAULT_MAIN_SUBTITLE,
}) {
  const navigate = useNavigate();
  const { setLendersContext } = useLendersContext();
  const { formData, clearStorage } = useFormDataContext();
  const { appContext } = useAppContext();
  const { step } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { formState } = methods;
  const { errors } = formState;

  const handleGlobalSubmit = async (data) => {
    setIsLoading(true);

    const result = await submitForm({ ...data, ...appContext });

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.status === 'matched') {
      lendersContextData.lenders = result.lenders;
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    if (showELocalPhone(lendersContextData.zipCode, 'US')) {
      lendersContextData.eLocalPhone = LOCAL_PHONE_NUMBER;
    }

    setLendersContext(lendersContextData);

    const route =
      result.status === 'matched'
        ? '/thank-you/lender-list'
        : '/thank-you/alternative';

    clearStorage();
    navigate(route);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="main-form__wrapper">
          <div className="main-form">
            <h2 className="main-form__title">{title}</h2>
            <h3 className="main-form__subtitle">{subtitle}</h3>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleGlobalSubmit)}>
                {!isEmpty(errors) && (
                  <div className="form-step__error-box">
                    {getErrorMessage(errors)}
                  </div>
                )}
                <div className="main-form__step-container">
                  <div className="main-form__step-info">STEP {step} of 3</div>
                  {step === 1 && <Step1 />}
                  {step === 2 && <Step2 />}
                  {step === 3 && <Step3 />}

                  {step === 1 && (
                    <div className="main-form__img-container">
                      <img
                        className="main-form__img"
                        src={BBBLogo}
                        alt="Accredit Business"
                      />
                    </div>
                  )}
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      )}
    </>
  );
}

export default MainForm;
